<template>
  <a-modal :maskClosable="false" title="推荐用字" :visible="open" @ok="save" @cancel="cancel" width="600px" destroyOnClose>
    <div @mouseover="topCenterMouseover" slot="closeIcon">
      <a-icon type="close" />
    </div>
    <a-spin @mouseover="topCenterMouseover" :style="backCss" :spinning="loading">
      <div style="max-height:500px;overflow-y:auto">
        <a-row style="line-height:40px;">
          <a-col :span="4">原文</a-col>
          <a-col :span="12">推荐用字</a-col>
          <a-col :span="5">手动输入</a-col>
          <a-col :span="3"></a-col>
        </a-row>
        <a-row
          style="display: flex;align-items: start;margin-top:12px;"
          v-for="(item, index) in recommendList"
          :key="index"
        >
          <a-col :span="4" style="padding-right:12px;">
            <a-input :maxLength="1" placeholder="请输入" @blur="keyBlur(item)" v-model="item.key"></a-input>
          </a-col>
          <a-col :span="12" style="line-height:40px;padding-right:12px;">
            <a-spin :spinning="inputLoading">
              <!-- <a-input placeholder="请输入" v-model="item.value"></a-input> -->
              <div class="select-recommend">
                <div
                  @click="selectRecommend(i, item)"
                  :class="i.selected ? 'select-recommend-item select-recommend-item-selected' : 'select-recommend-item'"
                  v-for="i in item.values"
                  :key="i.value"
                >
                  <a-icon v-if="i.selected" type="check-circle" theme="filled" />
                  {{ i.value }}
                </div>
              </div>
            </a-spin>
          </a-col>
          <a-col :span="5">
            <a-input placeholder="请输入" v-model="item.value"></a-input>
          </a-col>
          <a-col :span="3">
            <div class="end-btns-table">
              <a-button
                :disabled="recommendList.length == 1"
                class="item-btn"
                shape="circle"
                size="small"
                icon="minus"
                @click.stop="delItem(index)"
              />
              <a-button class="item-btn" shape="circle" size="small" icon="plus" @click.stop="addItem(index)" />
            </div>
          </a-col>
        </a-row>
      </div>
    </a-spin>
    <template slot="footer">
      <div @mouseover="topCenterMouseover">
        <a-button key="back" @click="cancel">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="save">
          确定
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { getAction, postAction, putAction } from '@/api/manage'
export default {
  props: {
    parent: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      open: false,
      loading: false,
      formData: {},
      recommendList: [],
      text: '',
      inputLoading: false,
      type: ''
    }
  },
  computed: {
    backCss() {
      let color = this.$store.state.app.color
      return {
        '--theme-color': color
      }
    }
  },
  methods: {
    topCenterMouseover() {
      this.$emit('topCenterMouseover')
    },
    selectRecommend(i, item) {
      item.values.forEach(value => {
        value.selected = false
      })
      i.selected = true
      item.value = i.value
    },
    addItem(index) {
      this.recommendList.splice(index + 1, 0, { key: '', value: '', values: [] })
    },
    delItem(index) {
      this.recommendList.splice(index, 1)
    },
    cancel() {
      this.open = false
      this.formData = {}
      this.recommendList = []
      this.text = ''
      this.type = ''
    },
    openModal(data, text, type) {
      this.isEdit = false
      this.recommendList = []
      this.type = type
      this.text = text
      this.recommendList = this.text.split('').map(item => {
        return {
          key: item,
          values: [],
          value: ''
        }
      })
      data.blacklist?.forEach(item => {
        item.words?.forEach(w => {
          this.recommendList.forEach(p => {
            if (p.key == w.value) {
              if (w.target) {
                w.target.split('').forEach(t => {
                  p.values.push({
                    value: t,
                    selected: false
                  })
                })
              }
            }
          })
        })
      })
      this.open = true
    },
    async keyBlur(data) {
      let key = data.key
      if (data.values.length) return
      this.inputLoading = true
      const res = await getAction('/guide/list', {
        current: 1,
        fieId: 'guidName',
        size: -1,
        typeId: 4
      })
      this.inputLoading = false
      if (res.code == 200) {
        let arr = res.data.records.filter(item => item.classId == 16)
        let recommendTextData = arr?.find(item =>
          item.departments.some(d => this.parent.departmentIds.some(dId => dId == d.id))
        )
        if (recommendTextData) {
          recommendTextData.blacklist?.forEach(item => {
            item.words?.forEach(w => {
              this.recommendList.forEach(p => {
                if (p.key == w.value && p.key == key) {
                  if (w.target) {
                    p.values = []
                    w.target.split('').forEach(t => {
                      p.values.push({
                        value: t,
                        selected: false
                      })
                    })
                  }
                }
              })
            })
          })
        }
        this.$forceUpdate()
      } else {
        this.$message.error(res.msg)
      }
    },
    save() {
      this.$emit('saveRecommend', { list: this.recommendList, type: this.type })
      this.cancel()
    }
  }
}
</script>

<style lang="less" scoped>
.select-recommend {
  display: flex;
  line-height: 40px;
  align-items: center;
  flex-wrap: wrap;
  .select-recommend-item-selected {
    border: 1px solid var(--theme-color) !important;
  }
  .select-recommend-item {
    height: 32px;
    width: 32px;
    border-radius: 4px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 32px;
    margin-right: 10px;
    border: 1px solid #d9d9d9;
    transition: 0.3s all;
    cursor: pointer;
    position: relative;
    &:hover {
      border-color: var(--theme-color);
    }
    .anticon-check-circle {
      color: var(--theme-color);
      position: absolute;
      font-size: 11px;
      top: -5px;
      right: -5px;
    }
  }
}
/deep/ .ant-modal-wrap {
  z-index: 1050 !important;
}
.ant-form {
  padding-bottom: unset !important;
}
.end-btns-table {
  transform: scale(0.9, 0.9);
  display: flex;
  flex-wrap: wrap;
  width: 40px;
  justify-content: center;
  align-items: start;
  position: relative;
  top: -10px;
}
</style>
