var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        maskClosable: false,
        title: "推荐用字",
        visible: _vm.open,
        width: "600px",
        destroyOnClose: "",
      },
      on: { ok: _vm.save, cancel: _vm.cancel },
    },
    [
      _c(
        "div",
        {
          attrs: { slot: "closeIcon" },
          on: { mouseover: _vm.topCenterMouseover },
          slot: "closeIcon",
        },
        [_c("a-icon", { attrs: { type: "close" } })],
        1
      ),
      _c(
        "a-spin",
        {
          style: _vm.backCss,
          attrs: { spinning: _vm.loading },
          on: { mouseover: _vm.topCenterMouseover },
        },
        [
          _c(
            "div",
            { staticStyle: { "max-height": "500px", "overflow-y": "auto" } },
            [
              _c(
                "a-row",
                { staticStyle: { "line-height": "40px" } },
                [
                  _c("a-col", { attrs: { span: 4 } }, [_vm._v("原文")]),
                  _c("a-col", { attrs: { span: 12 } }, [_vm._v("推荐用字")]),
                  _c("a-col", { attrs: { span: 5 } }, [_vm._v("手动输入")]),
                  _c("a-col", { attrs: { span: 3 } }),
                ],
                1
              ),
              _vm._l(_vm.recommendList, function (item, index) {
                return _c(
                  "a-row",
                  {
                    key: index,
                    staticStyle: {
                      display: "flex",
                      "align-items": "start",
                      "margin-top": "12px",
                    },
                  },
                  [
                    _c(
                      "a-col",
                      {
                        staticStyle: { "padding-right": "12px" },
                        attrs: { span: 4 },
                      },
                      [
                        _c("a-input", {
                          attrs: { maxLength: 1, placeholder: "请输入" },
                          on: {
                            blur: function ($event) {
                              return _vm.keyBlur(item)
                            },
                          },
                          model: {
                            value: item.key,
                            callback: function ($$v) {
                              _vm.$set(item, "key", $$v)
                            },
                            expression: "item.key",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      {
                        staticStyle: {
                          "line-height": "40px",
                          "padding-right": "12px",
                        },
                        attrs: { span: 12 },
                      },
                      [
                        _c(
                          "a-spin",
                          { attrs: { spinning: _vm.inputLoading } },
                          [
                            _c(
                              "div",
                              { staticClass: "select-recommend" },
                              _vm._l(item.values, function (i) {
                                return _c(
                                  "div",
                                  {
                                    key: i.value,
                                    class: i.selected
                                      ? "select-recommend-item select-recommend-item-selected"
                                      : "select-recommend-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectRecommend(i, item)
                                      },
                                    },
                                  },
                                  [
                                    i.selected
                                      ? _c("a-icon", {
                                          attrs: {
                                            type: "check-circle",
                                            theme: "filled",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(i.value) +
                                        "\n              "
                                    ),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { span: 5 } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: item.value,
                            callback: function ($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("a-col", { attrs: { span: 3 } }, [
                      _c(
                        "div",
                        { staticClass: "end-btns-table" },
                        [
                          _c("a-button", {
                            staticClass: "item-btn",
                            attrs: {
                              disabled: _vm.recommendList.length == 1,
                              shape: "circle",
                              size: "small",
                              icon: "minus",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.delItem(index)
                              },
                            },
                          }),
                          _c("a-button", {
                            staticClass: "item-btn",
                            attrs: {
                              shape: "circle",
                              size: "small",
                              icon: "plus",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.addItem(index)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ]
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { on: { mouseover: _vm.topCenterMouseover } },
          [
            _c("a-button", { key: "back", on: { click: _vm.cancel } }, [
              _vm._v("\n        取消\n      "),
            ]),
            _c(
              "a-button",
              {
                key: "submit",
                attrs: { type: "primary" },
                on: { click: _vm.save },
              },
              [_vm._v("\n        确定\n      ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }